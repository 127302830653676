
.bodyContent {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: #000000;
    background-color: #cacacaaa;
}

.bodyContent nav {
    margin-right: -15px;
    margin-left: -15px;
}